import React from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"
import LeftColumn from "../components/leftColumn"
import Cookie from "../components/cookie"
import SocialMobile from "../components/socialMobile"
import {logos} from "../data/logos"
import {titlesTranslation} from "../data/titles/titlesTranslations";

export default function Home(data) {
    let language = data.pageContext.language;
    let t = data.pageContext.t;
    let titles = titlesTranslation[language];

    return (
      <div className="page">
          <Helmet>
              <title>{titles.homepage}</title>
              <meta name="description" content={titles.description}/>
          </Helmet>

          <LeftColumn language={language} data={data}/>

          <div className="right-col">
              <h1 className="max-w-636" dangerouslySetInnerHTML={{__html: t.home_header}}/>

              <div className="query block-mt-88">
                  <h1 className="query__text" dangerouslySetInnerHTML={{__html: t.home_query_header}}/>
                  <Link to={`/${language}/contact`} className="btn query__btn">
                      {t.home_query_btn} <img className="btn__img" src="/images/btn-arrow.svg" alt=""/>
                  </Link>
              </div>
              {/*
              <div className="client right-col__client">
                  <h1 className="client__header mb-56" dangerouslySetInnerHTML={{__html: t.home_client_header}}/>
                  <div className="client__text max-w-636 mb-56">
                      {t.home_client_text}
                  </div>
                  <div className="client__logo">
                      {logos.map((value, index) =>{
                          return <a key={index} href={value.url} target="_blank"><img src={value.image} className="client__logo-icon" alt="" /></a>;
                      })}
                  </div>
              </div>
              */}

              <SocialMobile/>
          </div>
          <Cookie language={language}/>
      </div>
  );
}
